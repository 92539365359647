*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

html {
	box-sizing: border-box;
	font-size: 62.5%;

	@media only screen and (max-width: $bp-largest) {
		font-size: 50%;
	}
}

body {
	display: grid;
	grid-template-rows: max-content;

	overflow: hidden;

	font-family: "Nexa", sans-serif;
	color: #fff;
	font-weight: 300;
	line-height: 1.6;

	& > * {
		grid-row: 1 / 2;
		grid-column: 1 / 2;
	}

	main {
		position: relative;
		z-index: 0;

		visibility: hidden;

		&.show {
			visibility: visible;
		}
	}
}
