.tippy-box[data-theme~="modern-light"] {
	padding: 4px 6px;

	box-shadow: 0 0 2.4rem rgba(0, 0, 0, 0.2);
	background-color: #fff;
	color: #000;
}

.tippy-box[data-theme~="modern-light"] .tippy-content {
	font-size: 1.6rem;
	font-weight: 400;
}

.tippy-box[data-theme~="modern-light"][data-placement^="top"]
	.tippy-arrow::before,
.tippy-box[data-theme~="modern-light"][data-placement^="bottom"]
	.tippy-arrow::before {
	border-top-color: #fff;
	border-bottom-color: #fff;
	transform: scale(1.45);
}

.tippy-box[data-theme~="modern-dark"] {
	padding: 4px 6px;

	box-shadow: 0 0 2.4rem rgba(0, 0, 0, 0.2);
	background-color: #000;
	color: #fff;
}

.tippy-box[data-theme~="modern-dark"] .tippy-content {
	font-size: 1.6rem;
	font-weight: 400;
}

.tippy-box[data-theme~="modern-dark"][data-placement^="top"]
	.tippy-arrow::before,
.tippy-box[data-theme~="modern-dark"][data-placement^="bottom"]
	.tippy-arrow::before {
	border-top-color: #000;
	border-bottom-color: #000;
	transform: scale(1.45);
}
