.desktop {
	height: 100vh;
	width: 100%;

	display: grid;
	grid-template: 100% / 100%;

	background: url("/img/wallpaper-1.png");
	background-size: cover;
	color: currentColor;

	& > * {
		grid-row: 1 / 2;
		grid-column: 1 / 2;
	}

	&--version-label {
		justify-self: start;
		align-self: start;

		padding-top: 1rem;
		padding-left: 1rem;

		font-size: 1.5rem;
		font-weight: 400;
		font-style: italic;
	}

	&--dock {
		align-self: end;
		justify-self: center;

		display: grid;
		row-gap: 2.4rem;

		&__default {
			margin-bottom: 1rem;
		}

		&__modern {
			padding: 2.4rem 2.8rem;
			padding-bottom: 1.2rem;

			border-radius: 10px 10px 0 0;
			background-color: #fff;
			color: #000;
		}

		&__classic {
			justify-items: center;
			grid-template-columns: repeat(2, auto);

			width: 100%;
			padding: 2rem 3rem;

			background-color: #fff;
			color: #000;
		}
	}

	&--apps-box {
		display: grid;
		grid-template-columns: repeat(4, max-content);
		column-gap: 3.4rem;
	}

	&--dock__classic &--apps-box {
		justify-self: start;
		column-gap: 2.4rem;
	}

	&--app:link,
	&--app:visited {
		cursor: pointer;
		height: 8rem;
		width: 8rem;

		background-color: #fff;
		color: #000;
		border-radius: 50%;
		box-shadow: 0 0 2.8rem rgba(0, 0, 0, 0.1);
		outline: none;

		display: grid;
		justify-content: center;
		align-items: center;

		.feather {
			height: 4rem;
			width: auto;
		}

		transition: transform 0.2s ease;

		&:hover {
			transform: translateY(-5px);
		}
	}

	&--dock__modern &--app,
	&--dock__classic &--app {
		background-color: #000;
		color: #fff;
	}

	&--dock__classic &--app {
		height: 6rem;
		width: 6rem;

		.feather {
			height: 3rem;
			width: auto;
		}
	}

	&--details {
		justify-self: center;

		display: grid;
		grid-template-columns: repeat(4, max-content);
		column-gap: 2.6rem;
		align-items: center;
	}

	&--dock__classic &--details {
		justify-self: end;
		margin-right: 10rem;
	}

	&--clock {
		font-size: 3.3rem;
		font-weight: 500;
	}

	&--details-divider {
		height: 4rem;
		width: 3px;
		background-color: currentColor;
		border-radius: 50px;
	}

	&--menu-icons {
		display: grid;
		grid-template-columns: repeat(2, max-content);
		column-gap: 3rem;

		a:link,
		a:visited {
			color: currentColor;

			height: 2.5rem;
			width: auto;

			outline: none;

			transition: transform 0.2s ease;

			&:hover {
				transform: translateY(-5px);
			}
		}
	}

	&--corner-logo {
		justify-self: end;
		align-self: end;

		margin-right: 1.4rem;
		margin-bottom: 1.4rem;
	}

	&--dock__classic ~ &--corner-logo {
		margin-right: 2.2rem;

		img {
			height: 7rem;
			width: auto;
		}
	}

	& #tippy-7,
	& #tippy-8,
	& #tippy-9 {
		.tippy-box {
			padding: 0;
		}

		.tippy-content {
			padding: 0;
		}
	}

	&--popup-testing-box {
		justify-self: center;
		align-self: center;

		background-color: #fff;
		border-radius: 6px;
		box-shadow: 0 0 2.4rem rgba(0, 0, 0, 0.1);
	}

	&--popup {
		padding: 2.4rem;

		display: grid;
		row-gap: 2rem;

		color: currentColor;
		line-height: 1.2;

		h4 {
			font-size: 2.6rem;
			font-weight: 500;
		}

		h5,
		p {
			font-size: 2.2rem;
			font-weight: 400;
		}

		&__info {
			h5 {
				font-size: 2.4rem;
				font-weight: 500;
			}

			p {
				width: 35rem;
			}
		}

		&__clock {
			h3 {
				font-size: 3.4rem;
				font-weight: 600;
			}

			h4 {
				font-size: 2.4rem;
				font-weight: 500;
			}
		}
	}

	&--popup-setting-box {
		margin-left: 1.6rem;

		display: grid;
		row-gap: 1.6rem;
	}

	&--popup-btn-row {
		display: grid;
		grid-template-columns: repeat(3, max-content);
		column-gap: 1.8rem;

		a:link,
		a:visited {
			height: auto;
			padding: 0.8rem 1.4rem;

			background-color: #fff;
			color: #000;
			border-radius: 6px;
			box-shadow: 0 0 2.4rem rgba(0, 0, 0, 0.1);

			font-size: 1.6rem;
			font-weight: 400;
			text-decoration: none;
		}
	}

	&--credit-row {
		display: grid;
		grid-template-columns: repeat(2, max-content);
		column-gap: 0.3rem;
		align-items: center;

		a:link,
		a:visited {
			font-size: 2.2rem;
			font-weight: 400;
			color: currentColor;
			text-decoration: none;

			transition: transform 0.2s ease;

			&:hover {
				transform: translateY(-4px);
			}
		}
	}
}
