.app {
	position: absolute;
	top: 0;
	z-index: 1;

	height: 100vh;
	width: 100%;
	padding: 2rem;
	display: none;

	background-color: #fff;
	color: #000;

	transition: opacity 0.2s ease, transform 0.2s ease;

	opacity: 0;
	transform: translateY(140%);

	.os-content {
		padding-bottom: 10rem;

		display: grid;
		grid-template-rows: repeat(2, max-content);
		row-gap: 8rem;
	}

	&.animate {
		opacity: 1;
		transform: translateY(0);
	}

	&--header-box {
		grid-row: 1 / 2;
		align-self: start;

		display: grid;
		grid-template-columns: repeat(2, max-content);
		column-gap: 1.6rem;
		align-items: center;

		h4 {
			font-size: 2.4rem;
			font-weight: 500;
		}
	}

	&--close-btn:link,
	&--close-btn:visited {
		height: 2.8rem;
		width: 2.8rem;

		display: flex;
		justify-content: center;
		align-items: center;

		background-color: #282828;
		color: #fff;

		border-radius: 50%;

		transition: transform 0.2s ease;

		&:hover {
			transform: translateX(-3px);
		}
	}

	&--content-box {
		grid-row: 2 / 3;

		padding: 0 10rem;

		display: grid;
		row-gap: 12rem;
	}

	// ABOUT & WORKS
	&--text-box {
		display: grid;
		row-gap: 2rem;

		img {
			height: 28rem;
			width: 28rem;
		}

		h3 {
			font-size: 4.2rem;
			font-weight: 600;
		}

		p {
			font-size: 2.2rem;
			font-weight: 300;

			width: 75rem;
		}

		&__img {
			grid-template: repeat(2, max-content) / repeat(2, max-content);
			column-gap: 3.6rem;
			align-items: start;

			img {
				grid-row: 1 / 3;
				grid-column: 1 / 2;

				border-radius: 8px;
				box-shadow: 0 0 2.4rem rgba(0, 0, 0, 0.1);
			}

			p {
				margin-top: -6rem;
			}
		}

		&__alt {
			justify-self: end;
			text-align: end;
		}

		&__alt + &__img img {
			grid-column: 2 / 3;
		}
	}

	&--work &--text-box__img:not(:first-of-type) img {
		border-radius: 10px;
		box-shadow: 0 0 2.8rem rgba(0, 0, 0, 0.1);
	}

	// CONTACT
	&--form-box {
		justify-self: end;
		text-align: end;

		display: grid;
		row-gap: 3.6rem;
		justify-items: end;

		h3 {
			font-size: 4.2rem;
			font-weight: 600;
		}

		input,
		textarea,
		button {
			padding: 2rem;
			padding-left: 2.5rem;

			width: 40rem;

			border: none;
			outline: none;
			background-color: #fff;

			font-family: inherit;
			font-size: 1.9rem;
			font-weight: 500;

			border-radius: 6px;
			box-shadow: 0 0 2.6rem rgba(0, 0, 0, 0.1);

			&::placeholder {
				opacity: 1;
				color: #b1b1b1;
			}

			transition: transform 0.2s ease;

			&:hover {
				transform: translateX(-5px);
			}

			&:focus {
				transform: translateX(-3px);

				&::placeholder {
					color: #929292;
				}
			}
		}

		button {
			cursor: pointer;
			grid-column: 2 / 3;

			padding: 1.6rem 3.4rem;
			width: auto;
			justify-self: end;
		}

		textarea {
			min-height: 34rem;
			resize: vertical;
		}
	}

	&--form-row {
		justify-self: end;

		display: grid;
		grid-template-columns: repeat(2, max-content);
		column-gap: 3rem;
		align-items: center;
	}

	&--message-status {
		display: grid;
		grid-template-columns: repeat(2, max-content);
		column-gap: 1.4rem;
		align-items: center;

		.feather {
			height: 2.5rem;
			width: auto;
		}

		h4 {
			font-size: 2rem;
			font-weight: 400;
		}

		&__success {
			color: #4ee766;
		}

		&__fail,
		&__validate-error {
			color: #e74e4e;
		}

		&__validate-error h4 {
			line-height: 1.18;
			text-align: start;
			width: 24rem;
		}

		opacity: 0;
		visibility: hidden;
		transform: translateX(10px);

		transition: opacity 0.35s ease, visibility 0.35s ease, transform 0.35s ease;

		&.show {
			opacity: 1;
			visibility: visible;
			transform: translateX(0);
		}
	}

	// SETTINGS
	&--settings-box {
		display: grid;
		row-gap: 2rem;

		h3 {
			font-size: 4.2rem;
			font-weight: 600;
		}

		&__alt {
			justify-self: end;
		}
	}

	&--setting-box {
		display: grid;
		row-gap: 2rem;

		h4 {
			font-size: 3.4rem;
			font-weight: 400;
		}

		&__wallpapers {
			grid-template: repeat(2, max-content) / repeat(2, max-content);
			gap: 4rem;
		}
	}

	&--option-row {
		display: grid;
		grid-template-columns: repeat(2, max-content);
		gap: 4rem;
	}

	&--option {
		display: grid;
		grid-template: repeat(2, max-content) / repeat(2, max-content);
		row-gap: 1.8rem;
		justify-items: start;

		img {
			grid-column: 1 / 3;

			height: auto;
			width: 34rem;

			border-radius: 6px;
			box-shadow: 0 0 2.4rem rgba(0, 0, 0, 0.1);
		}

		h4 {
			font-size: 3rem;
			font-weight: 500;
		}

		a:link,
		a:visited {
			justify-self: end;
			align-self: center;

			padding: 1.2rem 2rem;

			font-size: 2rem;
			font-weight: 500;
			text-decoration: none;
			line-height: 1;
		}

		&__wallpaper {
			grid-template: unset;

			a:link,
			a:visited {
				padding: 1.4rem 2.4rem;

				font-size: 2rem;
				font-weight: 500;
				text-decoration: none;
				line-height: 1;
			}
		}

		a:link,
		a:visited {
			background-color: #fff;
			color: #000;
			border-radius: 6px;
			box-shadow: 0 0 2.4rem rgba(0, 0, 0, 0.1);

			transition: transform 0.2s ease;

			&:hover {
				transform: translateY(-3px);
			}
		}
	}
}
