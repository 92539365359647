@font-face {
  font-family: "Nexa";
  src: url("/fonts/Thin.woff2") format("woff2"),
    url("/fonts/Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Nexa";
  src: url("/fonts/ThinItalic.woff2") format("woff2"),
    url("/fonts/ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Nexa";
  src: url("/fonts/Light.woff2") format("woff2"),
    url("/fonts/Light.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Nexa";
  src: url("/fonts/LightItalic.woff2") format("woff2"),
    url("/fonts/LightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Nexa";
  src: url("/fonts/Book.woff2") format("woff2"),
    url("/fonts/Book.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Nexa";
  src: url("/fonts/BookItalic.woff2") format("woff2"),
    url("/fonts/BookItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Nexa";
  src: url("/fonts/Regular.woff2") format("woff2"),
    url("/fonts/Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Nexa";
  src: url("/fonts/RegularItalic.woff2") format("woff2"),
    url("/fonts/RegularItalic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Nexa";
  src: url("/fonts/Bold.woff2") format("woff2"),
    url("/fonts/Bold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Nexa";
  src: url("/fonts/BoldItalic.woff2") format("woff2"),
    url("/fonts/BoldItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Nexa";
  src: url("/fonts/ExtraBold.woff2") format("woff2"),
    url("/fonts/ExtraBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Nexa";
  src: url("/fonts/ExtraBoldItalic.woff2") format("woff2"),
    url("/fonts/ExtraBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Nexa";
  src: url("/fonts/Heavy.woff2") format("woff2"),
    url("/fonts/Heavy.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Nexa";
  src: url("/fonts/HeavyItalic.woff2") format("woff2"),
    url("/fonts/HeavyItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Nexa";
  src: url("/fonts/Black.woff2") format("woff2"),
    url("/fonts/Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Nexa";
  src: url("/fonts/BlackItalic.woff2") format("woff2"),
    url("/fonts/BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Uni Sans";
  src: url("/fonts/uni-sans-regular.woff2") format("woff2"),
    url("/fonts/uni-sans-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}