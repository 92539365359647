.notification {
	z-index: 1;
	justify-self: end;
	align-self: start;

	margin-top: 1.4rem;
	margin-right: 1.6rem;
	padding: 2rem 2.4rem;

	display: grid;
	row-gap: 1.6rem;

	background-color: #fff;
	color: #000;
	border-radius: 4px;
	box-shadow: 0 0 2.4rem rgba(0, 0, 0, 0.1);

	opacity: 0;
	visibility: hidden;
	transform: translateX(130%);

	transition: opacity, 0.4s cubic-bezier(0.83, 0, 0.17, 1),
		visibility 0.4s cubic-bezier(0.83, 0, 0.17, 1),
		transform 0.4s cubic-bezier(0.83, 0, 0.17, 1);

	&.show {
		opacity: 1;
		visibility: visible;
		transform: translateX(0);
	}

	p {
		font-size: 1.6rem;
	}

	&--title-box {
		display: grid;
		grid-template-columns: repeat(2, auto);
		column-gap: 4rem;
		align-items: center;

		h2 {
			font-size: 2.4rem;
			font-weight: 600;
			line-height: 1.25;
		}

		a:link,
		a:visited {
			color: currentColor;

			transition: transform 0.2s ease;

			&:hover {
				transform: translateX(3px);
			}

			.feather {
				stroke-width: 2.4;
				height: 2.6rem;
				width: 2.6rem;
			}
		}
	}
}
